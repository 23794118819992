

























































import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'
import { TagData } from "piramis-base-components/src/components/Tags/types";
import Tags from 'piramis-base-components/src/components/Tags/Tags.vue';

import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    HelpMessage,
    Tags
  }
})
export default class MetricCard extends Vue {
  @Prop() title!: string

  @Prop() helpMessage!: string

  @Prop() value!: string | number

  @Prop() tags!: Array<TagData>

  @Prop({ type: Boolean, default: false }) stretch!:boolean

  get metricCardStyles(): Partial<CSSStyleDeclaration> {
    return {
      position: 'relative',
      ...this.stylesIfSlot
    }
  }

  get stylesIfSlot(): Partial<CSSStyleDeclaration> {
    if (this.$slots.chart) {
      return {
        paddingBottom: '0px'
      }
    }

    return {
      height: '100%',
    }
  }
}
